import { StreemAPI } from '@streem/api';
import { CompanySettings } from '@streem/sdk-react';
import appLogger from './app_logger';
import { CompanyPublicSettings } from './types';

interface Setting {
    name: string;
    value: string;
}

export const fetchCompanyPublicSettings = async (
    companyCode: string,
): Promise<CompanyPublicSettings> => {
    const response = (await StreemAPI.companies.listCompanyPublicSettings(companyCode)) as {
        settings: Setting[];
    };
    return convertSettingsArrayToPublicCompanySettings(response.settings);
};

export const fetchCompanySettings = async (companySid: string): Promise<CompanyPublicSettings> => {
    const response = (await StreemAPI.companies.listCompanySettings(companySid)) as {
        settings: Setting[];
    };
    return convertSettingsArrayToCompanySettings(response.settings);
};

export const convertSettingsArrayToPublicCompanySettings = (
    settings: Setting[],
): CompanyPublicSettings => {
    return {
        arEnabled: getPlatformSettingValue(settings, 'WEB_AR_ENABLED'),
        anonymousShareEnabled: getPlatformSettingValue(settings, 'ANONYMOUS_SHARE_ENABLED'),
        customPrivacyPolicyUrl: getPlatformSettingValue(settings, 'CUSTOMER_PRIVACY_POLICY_URL'),
        customTCUrl: getPlatformSettingValue(settings, 'CUSTOMER_TS_AND_CS_URL'),
        endRedirectUrl: getPlatformSettingValue(settings, 'CUSTOMER_REDIRECT_URL'),
        faceToFaceModeEnabled: getPlatformSettingValue(settings, 'FACE_TO_FACE_MODE_ENABLED'),
        gpsEnabled: getPlatformSettingValue(settings, 'GPS_ENABLED'),
        hideStreemBranding: getPlatformSettingValue(settings, 'SWAC_HIDE_STREEM_BRANDING'),
        logoUrl: getPlatformSettingValue(settings, 'COMPANY_LOGO_URL'),
        screenshareEnabled: getPlatformSettingValue(settings, 'SCREENSHARE_ENABLED'),
        unreleasedFeaturesEnabled: getPlatformSettingValue(settings, 'UNRELEASED_FEATURES_ENABLED'),
        watermarkUrl: getPlatformSettingValue(settings, 'COMPANY_WATERMARK_URL'),
    };
};

export const convertSettingsArrayToCompanySettings = (settings: Setting[]): CompanySettings => {
    const publicSettings = convertSettingsArrayToPublicCompanySettings(settings);
    return {
        ...publicSettings,
        expertAvailabilityEnabled: getPlatformSettingValue(settings, 'EXPERT_AVAILABILITY_ENABLED'),
        chimeNetworkAdaptationEnabled: getPlatformSettingValue(
            settings,
            'CHIME_NETWORK_ADAPTATION_ENABLED',
        ),
        chimeLowBandwidthOptimizationEnabled: getPlatformSettingValue(
            settings,
            'CHIME_LOW_BANDWIDTH_OPTIMIZATION_ENABLED',
        ),
        preferredVideoCodecs: getPlatformSettingValue(settings, 'PREFERRED_CODECS'),
        roomNoteCharLimit: getPlatformSettingValue(settings, 'ROOM_NOTE_CHAR_LIMIT'),
        streemshotNoteCharLimit: getPlatformSettingValue(settings, 'STREEMSHOT_NOTE_CHAR_LIMIT'),
    };
};

export const getPlatformSettingValue = (settings: Setting[], name: string): any => {
    const setting = settings.find(s => s.name === name);
    if (!setting) {
        appLogger.error({ msg: `No platform setting found`, name: name });
        return;
    }
    const value = setting.value;
    if (value === 'false') {
        return false;
    } else if (value === 'true') {
        return true;
    }

    return value;
};
